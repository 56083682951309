import { getMenusList, getGroupBigScreen } from '../api/userapi'
import utils from '../utils/utils'

const menuList = {
  namespaced: true,
  state: {
    groupInfo: {}, // 集团信息
    allMenuList: [], // 接口返回的menu列表
    activeMenu: '', // 当前展示的导航菜单
    // 高亮展示的头部导航
    activeHearderMenu: '',
    splitIndex: 0, // 计算需要展示和隐藏的index位置
    hearderMenuList: [], // 头部导航
    leftMenuList: [], // 左侧导航
    isBigScreen: false, // 是否大屏
    allTabList: [], // 接口获取的所有的tab列表
    activeTabList: [], // 已经打开过的tab列表
    currentBtnList: [], // 当前页面按钮权限列表
    activeIndex: '', // 开发者及小助手中 当前展示的导航菜单
    activeBreadcrumbList: [], // 开发者及小助手中 当前展示的面包屑导航
    isRoutW: false, // 路内 false,路外 true；
    isShowMsg: '', // 消息通知是否展示
    three3DUrl: null, // 是否是3D网页
    isScene: false, // 是否是模块
    domainAddress: '', // 访问域名地址
    isRefreshBigScreent: false, // 更新自定义大屏
    isBigScreenShowModule: true, // 是否是地图版本
    isMenuRoute: false, // 是否是开发者及小助手
    menuData: [],
    isWorkSpace: false // 是否是不需要tab标签和全屏标签的工作台
  },
  getters: {
    // 首页主页风格
    theme: (state) => {
      return state.groupInfo.theme || 1
    },
    getTabName: (state) => (tabName) => {
      // console.log('getTabName-----', state.allTabList);
      const item = state.allTabList.find(item => item.key === tabName)
      return typeof (item) === 'undefined' ? '' : item.value
    },
    shortcutList: (state) => {
      // console.log('state.groupInfo---', state.groupInfo);
      return state.groupInfo.shortcut || []
    },
    // "shortcut": [6, 1, 2]//快捷入口 //对应枚举 GroupShortCut
    // 自定义大屏列表
    screenCustomList: (state) => {
      return state.groupInfo.screen_custom
    }

  },
  actions: {
    // 获取集团信息
    async fngetGroupBigScreen ({ commit, state }) {
      console.log('fngetGroupBigScreen------')
      const params = {}
      if (state.isScene) {
        params.is_scene = state.isScene
        params.domain_address = state.domainAddress
      }
      const res = await getGroupBigScreen(params)
      console.log('fngetGroupBigScreen------', res)
      if (res && res.Code === 200) {
        commit('setGroupInfo', res.Data)
      }
    },
    // 获取导航菜单列表
    async requestMenus ({ commit, state }) {
      const params = {}
      if (state.isScene) {
        params.is_scene = state.isScene
        params.domain_address = state.domainAddress
      }
      const res = await getMenusList(params)
      if (res && res.Code === 200) {
        commit('setAllMenuList', res.Data)
      }
    }
  },
  mutations: {
    setBigScreenShowModule (state, flag) {
      state.isBigScreenShowModule = flag
    },
    setIsMenuRoute (state, flag) {
      state.isMenuRoute = flag
    },
    // 更新大屏
    setRefreshBigScreent (state, flag) {
      console.log('setRefreshBigScreent更新大屏')
      state.isRefreshBigScreent = flag
    },
    // 设置主题色
    setTheme (state, theme) {
      state.groupInfo.theme = theme
    },
    // 设置菜单
    setAllMenuList (state, list) {
      state.menuData = list
      var newList = []
      state.isBigScreen = false
      if (state.groupInfo.screen_custom && state.groupInfo.screen_custom.length > 0) {
        // console.log('groupInfo.screen_custom', state.groupInfo.screen_custom)
        var newChildren = []
        state.groupInfo.screen_custom.forEach((item, index) => {
          let menuUrl = ''
          // 修改大屏配置，如果大屏为3D地址且为内部路由页面则直接添加内部页面，3D地址则直接为http地址，其余地图大屏为写死index数值
          if (item.screen_3D_domain && item.screen_3D_domain === '/mclz') {
            menuUrl = '/custom-big-screen' + item.screen_3D_domain
            // menuUrl = '/custom-big-screen?index=' + index // 自定义大屏页
          } else if (item.screen_3D_domain && item.screen_3D_domain === '/mclz-light') {
            menuUrl = '/custom-big-screen' + item.screen_3D_domain // 浅色明厨亮灶
          } else {
            menuUrl = '/custom-big-screen?index=' + index // 自定义大屏页
          }
          newChildren.push({
            menu_id: index + 1,
            menu_name: item.screen_name,
            show_name: item.screen_name,
            menu_icon: '',
            menu_url: menuUrl, // 自定义大屏页
            sort_number: index + 1,
            is_btn: false,
            is_folder: false,
            screen_pic: item.screen_pic,
            screen_type: item.screen_type
          })
        })
        if (newChildren.length > 0) {
          newList.push({
            menu_id: '',
            menu_name: '大屏',
            show_name: '大屏',
            menu_icon: '',
            menu_url: '',
            code: '',
            sort_number: 1,
            is_btn: false,
            is_folder: true,
            children: newChildren
          })
        }
        // console.log('newChildren----', newChildren);

        state.isBigScreen = true
      }
      list = newList.concat(list)
      // 保存菜单
      state.allMenuList = list
      state.hearderMenuList = list
      // { key: "/group-manage", value: "集团管理", isBigScreen: false }
      var newArr = []
      loopMenuList(list.concat(), newArr)
      // console.log(newArr)
      state.allTabList = newArr // 接口获取的所有的tab列表
      // 默认展示第一项菜单
      state.activeMenu = parseJson(list, 0)
      // 左侧展示
      state.leftMenuList = list[0].children

      // 头部展示菜单
      state.activeHearderMenu = list[0].show_name
    },
    // 设置集团信息
    setGroupInfo (state, groupInfo) {
      state.groupInfo = groupInfo
      // console.log('leftMenuList---111---', state.leftMenuList)
      console.log('!!!!groupInfo', groupInfo)
      if (state.isRefreshBigScreent) {
        var newList = []
        if (state.groupInfo.screen_custom) {
          var newChildren = []
          state.groupInfo.screen_custom.forEach((item, index) => {
            // console.log(item)
            newChildren.push({
              menu_id: index + 1,
              menu_name: item.screen_name,
              show_name: item.screen_name,
              menu_icon: '',
              menu_3D_domain: item.screen_3D_domain, // 新增自定义的3D大屏数据
              menu_url: '/custom-big-screen?index=' + index, // 自定义大屏页
              sort_number: index + 1,
              is_btn: false,
              is_folder: false,
              screen_pic: item.screen_pic,
              screen_type: item.screen_type
            })
            // this.setThree3DUrl(item.screen_3D_domain)
          })
          if (newChildren.length > 0) {
            newList.push({
              menu_id: '',
              menu_name: '大屏',
              show_name: '大屏',
              menu_icon: '',
              menu_url: '',
              code: '',
              sort_number: 1,
              is_btn: false,
              is_folder: true,
              children: newChildren
            })
          }
        }
        var list = newList.concat(state.menuData)
        // console.log('list', list)
        // 保存菜单
        state.allMenuList = list
        state.hearderMenuList = list
        var newArr = []
        loopMenuList(list.concat(), newArr)
        // console.log('newArrs', newArr)
        state.allTabList = newArr // 接口获取的所有的tab列表
        // console.log('leftMenuList-----222-', state.leftMenuList)
      }
    },
    // 判断是否以/routw开头
    setRoutStatus (state, path) {
      state.isRoutW = path != null && path.startsWith('/routw')
    },
    // 如果3D 大屏有数据设置3D大屏地址
    setThree3DUrl (state, url) {
      state.three3DUrl = url
    },
    // 设置是否是模块
    setScene (state, isScene) {
      state.isScene = isScene
    },
    // 设置是否是模块
    setDomainAddress (state, domainAddress) {
      state.domainAddress = domainAddress
    },
    setSplitIndex (state, index) {
      state.splitIndex = index
    },
    // 设置左侧导航菜单
    setLeftMenuList (state, list) {
      // console.log('设置左侧导航菜单----', list)
      state.leftMenuList = list
    },
    // 设置高亮展示的头部导航
    setActiveHearderMenu (state, name) {
      // console.log('setActiveHearderMenu---' + name);
      state.activeHearderMenu = name
    },
    // 设置菜单当前展示的页面
    setActiveMenu (state, name) {
      state.activeMenu = name
    },
    // 添加ActiveTab
    addActiveTabList (state, name) {
      if (!name) return
      if (name === '/scene-configuration') return
      if (name === '/screen-selection') return
      const index = state.activeTabList.findIndex(item => item === name)
      if (index < 0) {
        state.activeTabList.unshift(name)
      }
      if (name === '/commend-center') {
        state.leftMenuList = []
      }
    },
    // 将隐藏tab展示在页面中
    setShowTab (state, tabName) {
      var newArr = utils.deepClone(state.activeTabList)
      var index = newArr.findIndex(item => item === tabName)
      if (index >= state.splitIndex) {
        newArr.splice(index, 1)
        state.activeTabList = newArr
        state.activeTabList.unshift(tabName)
      }
    },
    // 设置是否展示左侧菜单
    setIsBigScreen (state, tab) {
      // const item = state.allTabList.find(item => item.key === tab);
      // 设置大屏菜单(前端写死)
      var bigList =
                [
                  '/smart-park',
                  '/commend-center',
                  '/custom-big-screen',
                  '/home/ychjgzt',
                  '/home/call-recording',
                  '/home/switch-record',
                  '/scene-configuration',
                  '/screen-selection'
                ]
      if (bigList.indexOf(tab) !== -1) {
        state.isBigScreen = true
      } else if (tab.indexOf('/custom-big-screen') !== -1) {
        state.isBigScreen = true
      } else {
        state.isBigScreen = false
      }
      // console.log("==--=-设置是否展示左侧菜单=-item=-=", item);
      //  state.isBigScreen = (typeof item === 'undefined') ? false : item.isBigScreen;
    },
    //  关闭当前tab
    closeTab (state, tabName) {
      var newArr = utils.deepClone(state.activeTabList)
      var index = newArr.findIndex(item => item === tabName)
      newArr.splice(index, 1)
      if (index < state.splitIndex) {
        if (index >= newArr.length) {
          state.activeMenu = newArr[newArr.length - 1]
        } else {
          state.activeMenu = newArr[index]
        }
      }
      state.activeTabList = newArr
    },
    // 关闭所有tab
    closeAllTab (state) {
      state.activeTabList = []
    },
    // 关闭其它tab
    closeOtherTab (state) {
      var newArr = state.activeTabList.filter((item) => {
        return item === state.activeMenu
      })
      state.activeTabList = newArr
    },
    // 添加一个tab到接口返回的tab中
    addTab (state, tag) {
      if (!tag) {
        return
      }
      const index = state.activeTabList.findIndex(item => item === tag.key)
      if (index < 0) {
        state.activeTabList.push(tag.key)
        state.allTabList.push(tag)
      }
    },
    // 获取按钮权限
    setCurrentBtn (state, tag) {
      var list = utils.fnGetBtnList(state.allMenuList, tag, [])
      // console.log('setCurrentBtn---', JSON.stringify(list))
      // list.forEach((item) => {
      //     console.log(item.show_name, '********', item.menu_id + '********', item.code);
      // })
      state.currentBtnList = list
    },
    // 开发者，小助手
    setDevAndHelpList (state, data) {
      console.log('开发者，小助手', data)
      if (state.activeMenu !== '/home') {
        state.activeMenu = '/home'
      }
      state.activeTabList = []
      // if (data.type === 1) { // 开发者
      //     state.activeHearderMenu = '开发者';
      //     state.hearderMenuList = [{
      //         menu_id: 1,
      //         menu_name: '开发者',
      //         show_name: '开发者',
      //         menu_icon: "",
      //         menu_url: '', // 自定义大屏页
      //         code: "001001",
      //         sort_number: 1,
      //         is_btn: false,
      //         is_folder: true,
      //         children: data.list,
      //     }]
      // } else if (data.type === 2) {
      //     state.activeHearderMenu = '小助手';
      // state.hearderMenuList = [{
      //     menu_id: 2,
      //     menu_name: '小助手',
      //     show_name: '小助手',
      //     menu_icon: "",
      //     menu_url: '', // 自定义大屏页
      //     sort_number: 1,
      //     is_btn: false,
      //     is_folder: true,
      //     children: data.list,
      // }]
      // }
      // state.leftMenuList = data.list;
      state.hearderMenuList = data.list
      state.activeHearderMenu = data.list[0].title
      state.leftMenuList = data.list[0].children
      console.log('data.list[0]----', data.list[0])
      var item = utils.filterTreeData(data.list)
      // console.log('filterTreeData-----', data.list);
      state.activeIndex = item ? item.id : (data.list[0].children.length > 0 ? data.list[0].children[0].id : data.list[0].id)
    },
    // 根据云平台，工作台，运维中心设置导航菜单
    setMenuList (state, name) {
      state.activeTabList = []
      if (name === '工作台') {
        state.activeHearderMenu = '工作台'
        state.activeMenu = '/home/ychjgzt'
        state.hearderMenuList = [{
          menu_id: 1,
          menu_name: '工作台',
          show_name: '工作台',
          menu_icon: '',
          menu_url: '/home/ychjgzt',
          sort_number: 1,
          is_btn: false,
          is_folder: false,
          children: []
        }, {
          menu_id: 2,
          menu_name: '呼叫记录',
          show_name: '呼叫记录',
          menu_icon: '',
          menu_url: '/home/call-recording',
          sort_number: 2,
          is_btn: false,
          is_folder: false,
          children: []
        },
        {
          menu_id: 3,
          menu_name: '开关闸记录',
          show_name: '开关闸记录',
          menu_icon: '',
          menu_url: '/home/switch-record',
          sort_number: 3,
          is_btn: false,
          is_folder: false,
          children: []
        }]
        state.leftMenuList = []
        // state.activeBreadcrumbList.unshift(state.activeHearderMenu)
      } else {
        // 保存菜单
        var list = state.allMenuList
        // 默认展示第一项菜单
        state.activeMenu = parseJson(list, 0)
        // 头部展示菜单
        state.activeHearderMenu = list[0].show_name
        state.hearderMenuList = list
        // { key: "/group-manage", value: "集团管理", isBigScreen: false }
        var newArr = []
        loopMenuList(list.concat(), newArr)
        state.allTabList = newArr // 接口获取的所有的tab列表
        // 左侧展示
        state.leftMenuList = list[0].children
      }
    },
    setActiveIndex (state, id) {
      state.activeIndex = id
    },
    setActiveBreadcrumbList (state, data) {
      state.activeBreadcrumbList = data
      state.activeBreadcrumbList.unshift(state.activeHearderMenu)
    },
    setIsWorkSpace (state, flag) {
      state.isWorkSpace = flag
    }

  }
}

function parseJson (data, index) {
  var str = ''
  function findFirstNode (data) {
    if (data[index]) {
      if (data[index].menu_url) {
        str = data[index].menu_url
      } else {
        findFirstNode(data[index].children)
      }
    }
  }
  findFirstNode(data)
  return str
}

function loopMenuList (data, newarr) {
  data.forEach(item => {
    if (!item.is_btn && !item.is_folder) {
      newarr.push({ key: item.menu_url, value: item.show_name })
    }
    if (item.children && item.children.length > 0) {
      loopMenuList(item.children, newarr)
    }
  })
  return newarr
}

export default menuList
