import Vue from '../../src/utils/prototypeConfig'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { parkingList, findList } from '../api/administrative'
import system from '../api/systemapi'
import { userInfo, getMenusList, GetAlarmCount, getLoginPageInfoByDomainAddr } from '../api/userapi'
import utils from '../utils/utils'
import { _ } from 'core-js'
import testAccount from './testAccount'
import menuList from './menuList'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    parkingList: [],
    originParkinList: [],
    allOptionsList: [],
    userInfo: {},
    projectInfo: {},
    role_list: [],
    groupList: [],
    permisionList: [],
    // topMenuList: [],
    // allTagList: [],
    // leftMunuList: [],
    // currentTag: '',
    // selectTitle: '',
    // tagPathList: [],
    // isBigScreen: false,
    firstLogin: false,
    isFullScreen: false,
    isShowModule: true, // 是否展示左右两侧数据
    alarmCount: '',
    queryObj: {},
    queryStatus: 1,
    // 每隔3秒请求一次
    everyThreeSecond: 0,
    mapStyle: '',
    platformInfo: {} // 平台默认数据配置
    // 当前有的按钮权限
    // currentBtnList: [],
  },
  getters: {
    getWayName: (state) => (codeType, value) => {
      if (!state.allOptionsList || state.allOptionsList.length <= 0) return
      const oldArray = state.allOptionsList.find(item => item.code === codeType)
      if (typeof (oldArray) === 'undefined' || oldArray === null || oldArray.length === 0 || oldArray.child === null) return []
      const newArray = utils.deepClone(oldArray.child)
      // if (newArray[0].value !== 0) {
      //     newArray.unshift({ value: 0, name: '全部' });
      // }
      const item = newArray.find(item => item.value === value)
      return item !== null && typeof item !== 'undefined' && item.name ? item.name : '无'
      // switch (codeType) {
      //     case 'VisitorSourceType':  // 访客来源
      //     case 'VisitorCheckStatus': // 组织是否审核
      //     case 'VisitorAuditStatus': // 访客审核状态
      //     case 'FaceExitType': // 人员通行记录出入类型
      //     case 'PersonType': // 人员身份类型
      //     case 'VerificationType': // 人员通行记录校验类型
      //     case 'permissionType': // 权限组设备通行规则
      //     case 'PoliticalType': // 人员信息政治面貌类型
      //     case 'EducationType': // 人员信息学历类型
      //     case 'BloodType': // 人员信息血型
      //     case 'NationType': // 人员信息民族类型
      //     case 'MaritalStatus': // 人员信息婚姻状况
      //     case 'PersonCardType': // 人员信息证件类型
      //     case 'CouponType': // 优惠劵类型
      //     case 'BusinessState': // 商家状态
      //     case 'SourceType':  // 商家来源
      //     case 'PackageStatus': // 账号/优惠券套餐状态
      //     case 'BusinessAutherState': // 商家审核状态
      //     case 'SchemeType': // 方案类型
      //     case 'ColorType': // 车牌颜色
      //     case 'InvoiceStatus': // 开票状态
      //     case 'InvoiceType': // 发票类型
      //     case 'TaxpayerType': // 纳税人类型
      //     case 'MessageType': // 系统消息类型
      //     case 'DeviceType': // 设备类型
      //     case 'InspectionOrder': // 巡检顺序
      //     case 'PlanStatus': // 巡检计划状态
      //     case 'AssetTaskStatus': // 巡检任务状态
      //     case 'AssetDealStatus': // 任务巡检项状态
      //     case 'AssetDealResult': // 任务巡检项结果
      //     case 'OperationType': // 操作类型
      //     case "ExitTypeInPark": // 路内出入场类型
      //     case "ExitTypeOffPark": // 路外出入场类型
      //     case "IdentifycameraType": // 车场车牌识别相机类型
      //     case "ExitType":
      //     case 'CarAlarmType':
      //     case 'CardType': // 卡类型
      //     case 'PaymentMethod': // 支付方式
      //     case 'PayeeType': // 收费方式
      //     case 'OrderSource': // 订单来源
      //     case 'OrderStatus': // 订单状态
      //     case 'CardStatus': // 缴费类型
      //     case 'Status': // 异常订单状态
      //     case 'TreatmentMethod': // 处理方式
      //     case 'CarOwnerCardType': // 车主卡片类型
      //     case 'AlarmType': // 告警类型
      //     case 'DeviceReceiveType': // 监控设备配置方式
      //     case 'TrafficType': // 路外停车场抓拍记录通行情况
      //     case 'FeedbackType': // 意见反馈类型
      //     case 'RegisterPersonType': // 组织成员类型
      //     case 'PersonAuditStatus': // 组织成员审核状态
      //     case 'UpgradeStatus': // 版本升级状态
      //     case 'AiboxAlarmType': // 边端分析盒子抓拍告警类型
      //         {
      //             const oldArray = state.allOptionsList.find(item => item.code === codeType);
      //             if (typeof (oldArray) === "undefined" || oldArray === null || oldArray.length === 0 || oldArray.child === null) return [];
      //             const newArray = utils.deepClone(oldArray.child);
      //             // if (newArray[0].value !== 0) {
      //             //     newArray.unshift({ value: 0, name: '全部' });
      //             // }
      //             const item = newArray.find(item => item.value === value);
      //             return item !== null && typeof item !== 'undefined' && item.name ? item.name : "无";
      //         }
      // }
    },
    getOptions: (state) => (codeType, origin = false) => {
      if (!state.allOptionsList || state.allOptionsList.length <= 0) return
      const oldArray = state.allOptionsList.find(item => item.code === codeType)
      if (typeof (oldArray) === 'undefined' || oldArray === null || oldArray.length === 0 || oldArray.child === null) return []
      var newArray = utils.deepClone(oldArray.child)
      if (codeType === 'RegisterPersonType') { // 组织成员类型
        newArray = newArray.filter(item => item.name !== '收费员')
      }
      return newArray
      // switch (codeType) {
      //     case 'VisitorSourceType':  // 访客来源
      //     case 'VisitorCheckStatus': // 组织是否审核
      //     case 'VisitorAuditStatus': // 访客审核状态
      //     case 'FaceExitType': // 人员通行记录出入类型
      //     case 'PersonType': // 人员身份类型
      //     case 'VerificationType': // 人员通行记录校验类型
      //     case 'permissionType': // 权限组设备通行规则
      //     case 'PoliticalType': // 人员信息政治面貌类型
      //     case 'EducationType': // 人员信息学历类型
      //     case 'BloodType': // 人员信息血型
      //     case 'NationType': // 人员信息民族类型
      //     case 'MaritalStatus': // 人员信息婚姻状况
      //     case 'PersonCardType': // 人员信息证件类型
      //     case 'CouponType': // 优惠劵类型
      //     case 'BusinessState': // 商家状态
      //     case 'SourceType':  // 商家来源
      //     case 'BusinessAutherState': // 商家审核状态
      //     case 'PackageStatus': // 账号/优惠券套餐状态
      //     case 'SchemeType': // 方案类型
      //     case 'TreatmentMethod': // 处理方式
      //     case 'ColorType': // 车牌颜色
      //     case 'CardType': // 卡类型
      //     case 'PaymentMethod': // 支付方式
      //     case 'PayeeType': // 收费方式
      //     case 'CardStatus': // 缴费类型
      //     case 'OrderSource': // 订单来源
      //     case 'OrderStatus': // 订单状态
      //     case 'Status': // 异常订单状态
      //     case 'CarOwnerCardType': // 车主卡片类型
      //     case 'AlarmType': // 告警类型
      //     case 'InvoiceType': // 发票类型
      //     case 'TaxpayerType': // 纳税人类型
      //     case 'AssetTaskStatus': // 巡检任务状态
      //     case 'AssetDealStatus': // 任务巡检项状态
      //     case 'AssetDealResult': // 任务巡检项结果
      //     case 'InspectionOrder': // 巡检顺序
      //     case 'OperationType': // 操作类型
      //     case "ExitType":
      //     case 'DeviceReceiveType': // 监控设备配置方式
      //     case 'ParkdeviceType': // 路外停车场设备配置方式
      //     case "ExitTypeInPark": // 路内出入场类型
      //     case "ExitTypeOffPark": // 路外出入场类型
      //     case "VoiceType": // 语音播报类型
      //     case "ShowType": // 显示屏屏显类型
      //     case "ShowfreeType":  // 显示屏屏显类型_空闲
      //     case "ShowStoredType": // 显示屏屏显类型_储值卡
      //     case "ShowMonthType": // 显示屏屏显类型_月租卡
      //     case "ShowVipType": // 显示屏屏显类型_贵宾卡
      //     case "IdentifycameraType": // 车场车牌识别相机类型
      //     case 'CarAlarmType':
      //     case 'TrafficType': // 路外停车场抓拍记录通行情况
      //     case 'FeedbackType': // 意见反馈类型
      //     case 'PersonAuditStatus': // 组织成员审核状态
      //     case 'DeviceType': // 设备类型
      //     case 'UpgradeStatus': // 版本升级状态
      //     case 'AiboxAlarmType': // 边端分析盒子抓拍告警类型
      //     case 'InvoiceStatus': // 开票状态
      //     case 'MessageType': // 系统消息
      //     case 'PlanStatus': // 巡检计划状态
      //         {
      //             const oldArray = state.allOptionsList.find(item => item.code === codeType);
      //             if (typeof (oldArray) === "undefined" || oldArray === null || oldArray.length === 0 || oldArray.child === null) return [];
      //             const newArray = utils.deepClone(oldArray.child);
      //             // newArray.unshift({ value: 5, name: '全部' });
      //             return newArray;
      //         }
      //     case 'RegisterPersonType': // 组织成员类型
      //         {
      //             const oldArray = state.allOptionsList.find(item => item.code === codeType);
      //             if (typeof (oldArray) === "undefined" || oldArray === null || oldArray.length === 0 || oldArray.child === null) return [];
      //             var newArray = utils.deepClone(oldArray.child);
      //             newArray = newArray.filter(item => item.name !== '收费员');
      //             // if (origin) {
      //             //     if (newArray[0].value !== 0) {
      //             //         newArray.unshift({ value: 0, name: '全部' });
      //             //     }
      //             // }
      //             return newArray;
      //         }
      // }
    },
    isAdmin: (state) => {
      const { user_level = '' } = state.userInfo
      // 1 admin  2 集团用户 3 普通用户
      if (parseInt(user_level) === 1) {
        return true
      } else {
        return false
      }
    },
    getUserInfo: (state) => {
      // console.log(state.userInfo);
      return state.userInfo
    },
    theme: (state) => {
      const { theme = 0 } = state.projectInfo
      return parseInt(theme)
    },
    // getTagName: (state) => (tag) => {
    //     const item = state.allTagList.find(item => item.key === tag);
    //     return typeof (item) === "undefined" ? '' : item.value;
    // },
    // 是否是特殊账号
    isSpecialUser: (state) => {
      if (state.userInfo.user_name === 'administrator') {
        return true
      }
      return false
    },
    mapIslight: (state) => {
      if (state.mapStyle === 'normal' || state.mapStyle === 'light' || state.mapStyle === 'macaron') {
        return true
      }
      return false
    },
    getAllParkingList: (state) => (type) => {
      // 区分停车场  1 - 路内停车场; 2 - 路外停车场；
      if (type === 1) { // 路内停车场
        return state.parkingList.filter(item => {
          return item.parktype === 1
        })
      } else if (type === 2) { // 路外停车场
        return state.parkingList.filter(item => {
          return item.parktype === 2
        })
      } else { // 全部
        return state.parkingList
      }
    }
  },
  mutations: {
    setplatformInfo (state, info) {
      state.platformInfo = info
      // state.platformInfo = {
      //     login_logo: info.login_logo,
      //     login_title: info.login_title,
      //     logo_url: info.logo_url,
      //     platform_name: info.platform_name,
      // }
    },
    setShowModule (state, flag) {
      state.isShowModule = flag
    },
    setMapStyle (state, style) {
      state.mapStyle = style
    },
    refreshTheBigScreen (state, num) {
      if (num === 0) {
        state.everyThreeSecond = 0
      } else {
        state.everyThreeSecond = (state.everyThreeSecond + num)
      }
    },
    changeScreenStatus (state, status) {
      state.isFullScreen = status
    },
    log_out (state) {
      state.parkingList = []
      state.originParkinList = []
      state.allOptionsList = []
      state.userInfo = {}
      state.role_list = []
      state.groupList = []
      state.permisionList = []
      // state.topMenuList = [];
      // state.allTagList = [];
      // state.leftMunuList = [];
      // state.currentTag = '';
      // state.selectTitle = '';
      // state.tagPathList = [];
      // state.isBigScreen = false;
      state.isFullScreen = false
      state.queryObj = {}
      state.queryStatus = 1
      state.projectInfo = {}
      state.mapStyle = ''
      // console.log("===state===", state);
      state.menuList.groupInfo = {}
      state.menuList.allMenuList = []
      state.menuList.activeMenu = ''
      state.menuList.activeHearderMenu = ''
      state.menuList.splitIndex = 0
      state.menuList.hearderMenuList = []
      state.menuList.leftMenuList = []
      state.menuList.isBigScreen = false
      state.menuList.allTabList = []
      state.menuList.activeTabList = []
      state.menuList.currentBtnList = []
      state.menuList.activeIndex = ''
      state.menuList.activeBreadcrumbList = []
      state.menuList.isRoutW = false
      state.menuList.isShowMsg = ''
      state.menuList.three3DUrl = null
      state.menuList.isRefreshBigScreent = false // 更新自定义大屏
      state.menuList.isBigScreenShowModule = true // 是否是地图版本
      state.menuList.isMenuRoute = false // 是否是开发者及小助手
      state.menuList.menuData = []
    },
    setQueryObj (state, obj) {
      state.queryObj = utils.deepClone(obj)
    },
    setQueryStatus (state, status) {
      state.queryStatus = status
    },
    setPorkingList (state, list) {
      state.originParkinList = list
      var newArray = utils.deepClone(list)
      // newArray.unshift({ parking_id: '', parking_name: '全部' });
      state.parkingList = newArray
    },
    setOptionsList (state, list) {
      state.allOptionsList = list
    },
    setUserInfo (state, userInfos) {
      state.userInfo = userInfos
      // console.log(userInfos.first_login);
      // state.psdVisible = userInfos.first_login === "true";
    },
    setProjectInfo (state, projectInfo) {
      state.projectInfo = projectInfo
    },
    setFirstLogin (state, flag) {
      state.firstLogin = flag
    },
    setroleList (state, data) {
      state.role_list = data
    },
    setpermisionList (state, data) {
      state.permisionList = data
    },
    setgetGropList (state, data) {
      state.groupList = data
    },
    // setTopMenuList(state, data) {
    //     // console.log("==--==--==state.topMenuList=", data);
    //     state.topMenuList = data.TreeList;
    //     state.allTagList = data.DataList;
    //     // console.log("==--==--==state.allTagList=", JSON.stringify(state.allTagList));
    // },
    setAlarmCount (state, data) {
      state.alarmCount = data.count
    }
    // setLeftMenuList(state, list) {
    //     // console.log("==-=-=leftMunuList-=-=", JSON.stringify(list));
    //     state.leftMunuList = list;
    // },
    // addTagList(state, tag) {
    //     // console.log(tag);
    //     if (!tag) {
    //         return;
    //     }
    //     const index = state.tagPathList.findIndex(item => item === tag);
    //     if (index < 0) {
    //         state.tagPathList.push(tag);
    //     } else {
    //         state.currentTag = tag
    //     }
    //     // console.log('tagPathList---', state.tagPathList);
    //     // console.log('allTagList---', state.allTagList);
    // },
    // addTagName(state, tag) {
    //     if (!tag) {
    //         return;
    //     }
    //     const index = state.tagPathList.findIndex(item => item === tag.key);
    //     if (index < 0) {
    //         state.tagPathList.push(tag.key);
    //         state.allTagList.push(tag);
    //     } else {
    //         state.currentTag = tag
    //     }
    // },
    // closeTag(state, tag) {
    //     var newArray = utils.deepClone(state.tagPathList);
    //     var index = newArray.findIndex(item => item === tag);
    //     newArray.splice(index, 1);
    //     // console.log("==--==newArray--==", JSON.stringify(newArray));
    //     state.tagPathList = newArray;
    //     // console.log('state.tagPathList---', state.tagPathList);
    // },
    // clearOtherTagList(state, tag) {
    //     var newArray = state.tagPathList.filter(item => {
    //         return item === tag
    //     })
    //     state.tagPathList = newArray;
    // },
    // setCurrentTag(state, tag) {
    //     state.currentTag = tag
    //     const item = state.allTagList.find(item => item.key === tag);
    //     // console.log("==--=-=-item=-=", item);
    //     state.isBigScreen = (typeof item === 'undefined') ? false : item.isBigScreen;
    // },
    // setCurrentBtn(state, tag) {
    //     var list = utils.fnGetBtnList(state.topMenuList, tag, []);
    //     // console.log('setCurrentBtn---', JSON.stringify(list));
    //     list.forEach((item) => {
    //         console.log(item.name, '****', item.code);
    //     })
    //     state.currentBtnList = list;
    // },
    // setSelectTitle(state, tag) {
    //     state.selectTitle = tag
    // },
    // setisBigScreen(state, status) {
    //     state.isBigScreen = status;
    // },
    // clearSelectTagList(state) {
    //     state.tagPathList = [];
    // }
  },
  actions: {
    async requestPorkingList ({ commit }) {
      // console.log("=-=-=requestPorkingList-=-=");
      const res = await parkingList()// 获取停车场列表
      await commit('setPorkingList', res.Data)
      console.log('停车列表', res.Data)
    },
    async requestFindList ({ commit }) {
      // 获取下拉款选项 包括 卡类型 方案类型 车主卡片类型 缴费类型 车牌颜色 设备类型 数据来源 告警类型 支付方式 订单来源 订单状态 收款类型 项目类型 消息类型 异常订单状态 高位相机类型 视频桩设备类型 处理结果
      // console.log('=-=-=requestFindList-=-=')
      const res = await findList()
      await commit('setOptionsList', res.Data)
    },
    async requestUserInfo ({ commit }) {
      const res = await userInfo()
      // console.log("==-=-=用户信息-=-==-", JSON.stringify(res));
      commit('setUserInfo', res)
      commit('setFirstLogin', res.first_login === 'true')
    },
    async requestRoleList ({ commit }) {
      const res = await system.getroleList()
      commit('setroleList', res.Data)
    },
    async requestPermisionList ({ commit }, params) {
      const res = await system.getParkingPermitList(params)
      // console.log("==--=getParkingPermitList=--==", res.Data);
      if (res && res.Code === 200) {
        commit('setpermisionList', res.Data)
      }
    },
    async getGropList ({ commit }) {
      const res = await system.getGropList()
      if (res && res.Code === 200) {
        commit('setgetGropList', res.Data)
      }
    },
    // async requestMenus({ commit }) {
    //     const res = await getMenusList();
    //     // console.log("==--==-==getMenusList=", JSON.stringify(res));
    //     if (res && res.Code === 200) {
    //         // console.log("==--==-==getMenusList=", JSON.stringify(res));
    //         commit('setTopMenuList', res.Data);
    //     }
    // },
    async requestAlarmCount ({ commit }) {
      const res = await GetAlarmCount()
      // console.log("==--==-==GetAlarmCount=", JSON.stringify(res));
      if (res && res.Code === 200) {
        console.log('==--==-==GetAlarmCount=', JSON.stringify(res))
        commit('setAlarmCount', res.Data)
      }
    },
    // 根据域名获取集团登录页信息
    // this.platformInfo = this.platformInfo;
    async fngetLoginPageInfoByDomainAddr ({ commit }) {
      // var domain_address = window.location.hostname === 'localhost' ? 'tcity.lebopark.com' : window.location.hostname;
      const res = await getLoginPageInfoByDomainAddr({
        // domain_address: 'tcity.leboparkzz.api.com'
        domain_address: window.location.hostname
      })
      if (res && res.Code === 200 && res.Data) {
        commit('setplatformInfo', res.Data)
      } else {
        commit('setplatformInfo', {
          login_logo: require('../assets/img/login-log.png'),
          login_title: '城市停车平台后台管理系统',
          // logo_url: require('../assets/img/home_logo.png'),
          logo_url: '',
          theme: 1,
          platform_name: '城市停车平台后台管理系统',
          login_page: 1
        })
      }
    }
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage
  })],
  modules: {
    testAccount,
    menuList
  }
})

export default store
