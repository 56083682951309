<template>
  <div id="app">
    <title>{{platformInfo.platform_name}}</title>
    <!-- <router-view /> -->
    <!-- 添加点击事件 -->
    <!-- <div id="app" style="height: 100%" @click="isTimeOut">
      <router-view />
    </div> -->
    <router-view />
  </div>
</template>
<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import { login, getSelDefault, getLoginPageInfoByDomainAddr } from './api/userapi'
import utils from './utils/utils'
export default {
  name: 'app',
  data () {
    return {
      lastTime: null, // 最后一次点击的时间
      currentTime: null, // 当前点击的时间
      timeOut: 30 * 60 * 1000 // 设置超时时间:30分钟
    }
  },
  computed: {
    ...mapGetters(['theme']),
    ...mapState(['platformInfo'])
  },
  watch: {
    // theme(newValue, oldValue) {
    //   setTimeout(() => { this.setTheme(); }, 200);
    // },
    $route (to, form) {
      // console.log("=-=-=-=to=", to.path);
      this.setRoutStatus(to.path)
      this.refreshToken()
    }
  },
  created () {
    this.$store.dispatch('fngetLoginPageInfoByDomainAddr')
    this.lastTime = new Date().getTime()
    // if (utils.getQueryString("access_toke")) {
    //   this.$router.push('/home')
    // }
    // setTimeout(() => { this.setTheme(); }, 200);
    // var style = localStorage.getItem("style");
    // console.log('style-----', style);
    // if (style) {
    //   document.getElementById("style").setAttribute("href", style);
    // } else {
    //   document.getElementById("style").setAttribute("href", "");
    // }
  },
  mounted () {
  },
  methods: {
    ...mapMutations('menuList', ['setRoutStatus']),
    ...mapMutations(['setplatformInfo']),
    // 根据域名获取集团登录页信息
    // async fngetLoginPageInfoByDomainAddr() {
    //   var domain_address = window.location.hostname === 'localhost' ? 'tcity.lebopark.com' : window.location.hostname;
    //   const res = await getLoginPageInfoByDomainAddr({
    //     // domain_address: 'tcity.leboparkzz.api.com'
    //     domain_address: domain_address,
    //   })
    //   console.log(res);
    //   if (res && res.Code === 200 && res.Data) {
    //     // var data = res.Data
    //     console.log(res.Data);
    //     this.setplatformInfo(res.Data);
    //     // this.login_page = data.login_page || 1
    //     // this.loginPlatformInfo.login_logo = data.logo_url || this.loginPlatformInfo.login_logo;
    //     // this.loginPlatformInfo.login_title = data.platform_name || this.loginPlatformInfo.login_title;
    //     // this.setplatformInfo({
    //     //   login_logo: this.loginPlatformInfo.login_logo,
    //     //   login_title: this.loginPlatformInfo.login_title,
    //     //   logo_url: this.loginPlatformInfo.login_logo,
    //     //   platform_name: this.loginPlatformInfo.login_title
    //     // });
    //   } else {
    //     this.setplatformInfo({
    //       login_logo: require("./assets/img/login-log.png"),
    //       login_title: "城市停车平台后台管理系统",
    //       logo_url: require("./assets/img/home_logo.png"),
    //       theme: 1,
    //       platform_name: "城市停车平台后台管理系统",
    //       login_page: 1,
    //     })
    //   }
    // },
    // async fngetSelDefault() {
    //   const res = await getSelDefault()
    //   console.log(res);
    //   if (res && res.Code === 200 && res.Data) {
    //     this.setplatformInfo(res.Data)
    //   }
    // },
    // setTheme() {
    // console.log(this.theme);
    //   let style = "";
    //   switch (this.theme) {
    //     case 1:
    //       style = "./css/one.css";
    //       break;
    //     case 2:
    //       style = "./css/two.css";
    //       break;
    //     case 3:
    //       style = "./css/three.css";
    //       break;
    //     case 4:
    //       style = "./css/four.css";
    //       break;
    //     case 5:
    //       style = "./css/five.css";
    //       break;
    //     default:
    //       style = "./css/two.css";
    //       break;
    //   }
    //   // localStorage.setItem("style", style);
    //   document.getElementById("style").setAttribute("href", style);
    // },
    isTimeOut () {
      this.currentTime = new Date().getTime() // 记录这次点击的时间
      if (this.currentTime - this.lastTime > this.timeOut) { // 判断上次最后一次点击的时间和这次点击的时间间隔是否大于30分钟
        if (sessionStorage.getItem('token')) { // 如果是登录状态
          this.$alert('您的登录已过期，请重新登录', '', {
            confirmButtonText: '确定',
            callback: action => {
              // console.log(action);
              sessionStorage.removeItem('token')
              this.$router.push('/login')
              // this.$message({

              //   type: 'info',
              //   message: `action: ${action}`
              // });
            }
          })
        } else {
          this.lastTime = new Date().getTime()
        }
      } else {
        this.lastTime = new Date().getTime() // 如果在30分钟内点击，则把这次点击的时间记录覆盖掉之前存的最后一次点击的时间
      }
    },
    async refreshToken () {
      const overTime = await sessionStorage.getItem('overexpirestime')
      const refreshToken = await sessionStorage.getItem('refreshtoken')
      const newTime = new Date().getTime()
      // console.log("(overTime - 0)= ", ((overTime - 0) > newTime));
      // console.log("(newTime > (overTime)= ", (newTime > (overTime - 60 * 59 * 1000)));
      // console.log("(newTime)= ", (newTime));
      // console.log("(overexpirestime)= ", (new Date().getTime() + 3600 * 1000));
      // console.log("(overTime)= ", (overTime));
      // console.log("(newTime > (overTime)= ", (overTime - 60 * 59 * 1000));
      // 在快过期的最后10分钟，如果使用过那么就刷新token
      if ((overTime - 0) > newTime && newTime > (overTime - 60 * 10 * 1000)) {
        try {
          const res = await login({
            client_id: 'parkinglot',
            client_secret: 'secret',
            grant_type: 'refresh_token',
            refresh_token: refreshToken
          })
          // console.log(res);
          if (res) {
            sessionStorage.setItem('token', res.access_token)
            sessionStorage.setItem('refreshtoken', res.refresh_token)
            sessionStorage.setItem('overexpirestime', `${new Date().getTime() + res.expires_in * 1000}`)
          }
        } catch (error) {

        }
      }
    }
  }
}
</script>

<style scoped lang="less">
#app {
  font-family: SourceHanSansCN;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #666;
  font-size: 14px;
}
.BMap_cpyCtrl {
  display: none;
}
.anchorBL {
  display: none;
}
</style>
