
import axios from 'axios'
// import qs from 'qs'
import { Message } from 'element-ui'
import router from '../router'
import store from '../store'
import utils from '../utils/utils'
// 设置基准地址
axios.defaults.baseURL = '/api'
// 设置请求超时
axios.defaults.timeout = 10000
// 设置post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
// 请求拦截器
axios.interceptors.request.use(
  config => {
    // 判断token是否存在
    // const token = store.state.token;
    // token && (config.headers.Authorization = token);
    const token = sessionStorage.getItem('token') || utils.getQueryString('access_toke')
    if (token) {
      config.headers.Authorization = 'Bearer ' + token
    } else {
      router.push('/login')
    }
    // if (config.method === "get") {
    //     config.params = qs.stringify(config.params)
    // } else
    // if (config.method === "post") {
    //     config.data = qs.stringify(config.data)
    // }
    // console.log(config);
    return config
  },
  error => {
    Message.error({
      message: '请求超时',
      duration: 1000
    })
    return Promise.error(error)
  })
// 响应拦截器
axios.interceptors.response.use(
  response => {
    // console.log('响应拦截器----', response);
    // 成功 = 200,
    // 订单已支付 = 301,
    // 普通提示=302,
    // 失败 = 500,
    // 传入参数不正确 = 501,
    switch (response.data.Code) {
      case 0:
      case 404:
      case 400:
      case 405:
      case 500:
      case 501:
      case 302:
        // 错误
        if (response.data.Message !== null) {
          Message.error({
            message: response.data.Message,
            duration: 1000
          })
        } else {
          Message.error({
            message: '未知错误',
            duration: 1000
          })
        }
        break
      default:
        return response.data
    }
    if (response.data.retcode === 1) {
      Message.error({
        message: response.data.Message || '未知错误',
        duration: 1000
      })
    }
    return response.data
  },
  error => {
    // console.log('响应拦截器--error--', error);
    // console.log('响应拦截器--error--', error.response);
    if (!error || !error.response) return
    switch (error.response.status) {
      case 401:
        sessionStorage.removeItem('token')
        store.commit('log_out')
        router.push('/login')
        break
      case 400:
        if (error.response.config.url === '/identity/connect/token') {
          localStorage.setItem('errNum', Number(JSON.parse(error.response.data.error).ErrorNum))
          Message.error({
            message: JSON.parse(error.response.data.error).Message,
            duration: 1000
          })
        } else {
          Message.error({
            message: error.response.statusText || '未知错误',
            duration: 1000
          })
        }
        break
      case 404:
      case 405:
      case 500:
      case 502:
        // 错误
        if (error.response.statusText !== null) {
          Message.error({
            message: error.response.statusText,
            duration: 1000
          })
        } else {
          Message.error({
            message: '未知错误',
            duration: 1000
          })
        }
        break
      default:
        return error.response.statusText
    }
  }
)
export default axios
