<template>
    <div class="switch_box" @click="batchChang(switchType)">
        <div :class="status? 'switch' : 'switch_check'"></div>
        <span :style="status? `color: ${turnOffColor};` : `color:${turnOnColor};`"> {{ status ?
           turnOff  : turnOn }}</span>
    </div>
</template>
<script>

export default {
  name: 'lebo-switch',
  props: {
    status: { default: true }, // 一个返回是Boolen的语句或值
    turnOn: { type: String, default: '开启' }, // 开启时的文字描述
    turnOff: { type: String, default: '关闭' }, // 关闭时的文字描述
    turnOnColor: { type: String, default: '#1acd90' }, // 开启时的颜色
    turnOffColor: { type: String, default: '#666666' }, // 关闭时的颜色
    switchType: { default: 0 } // 开启和关闭传递的值
  },
  data () {
    return {
      statusCode: ''
    }
  },
  created () { },
  mounted () {
    this.statusCode = this.switchType
  },
  computed: {},
  watch: {},
  methods: {
    // 传出父级swichChange方法 及变化值 type
    batchChang (type) {
      this.statusCode = type
      console.log('type', this.statusCode, type)
      this.$emit('switchChange', this.statusCode)
    }
  }
}
</script>
<style scoped lang="less">
.switch_box {
  display: flex;
  align-items: center;
  cursor: pointer;

  .switch {
    width: 35px;
    height: 18px;
    border-radius: 9px;
    margin-right: 10px;
    background-color: #cccccc;
    position: relative;

    &::before {
      content: "";
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 6px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 5px;
      transform: translate(0, -50%);
    }
  }

  .switch_check {
    width: 35px;
    height: 18px;
    border-radius: 9px;
    margin-right: 10px;
    background-color: #1acd90;
    position: relative;

    &::before {
      content: "";
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 6px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      right: 3px;
      transform: translate(0, -50%);
    }
  }
}</style>
