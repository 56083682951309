/**
 * 运维中心
 */
const maintenanceCenter = [
  // 运维中心 -- 数据核验 -- 出入记录审核    /access-record-check
  {
    path: '/access-record-check',
    name: 'accessRecordCheck',
    component: () => import('../view/maintenanceManagement/views/dataVerification/accessRecordCheck/index.vue')
  },
  // 运维中心 -- 公司官网 /companyweb-manage
  {
    path: '/companyweb-manage',
    name: 'companywebManage',
    component: () => import('../view/maintenanceManagement/views/companyOfficialWebsite/companywebManage/index.vue')
  },
  //  运维中心 -- 开发者 /developer-manage
  {
    path: '/developer-manage',
    name: 'developerManage',
    component: () => import('../view/maintenanceManagement/views/developerPage/developerManage/index.vue')
  },
  // //  运维中心 -- 开发者---- 新增或修改数据 /add-developer-manage
  // {
  //   path: '/add-developer-manage',
  //   name: 'addDeveloperManage',
  //   component: () => import('../view/maintenanceCenter/addDeveloperManage.vue')
  // },
  //  运维中心 -- 小助手   /helper-manage
  {
    path: '/helper-manage',
    name: 'helperManage',
    component: () => import('../view/maintenanceManagement/views/helperPage/helperManage/index.vue')
  },
  // //  运维中心 -- 小助手--- 新增或修改   /add-helper-manage
  // {
  //   path: '/add-helper-manage',
  //   name: 'addHelperManage',
  //   component: () => import('../view/maintenanceCenter/addHelperManage.vue')
  // },
  //  运维中心 -- 客户服务 -- 用户协议   /user-agreement
  {
    path: '/user-agreement',
    name: 'userAgreement',
    component: () => import('../view/maintenanceManagement/views/customerService/userAgreement/index.vue')
  },
  //  运维中心 -- 客户服务 -- 隐私政策   /privacy-policy
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    component: () => import('../view/maintenanceManagement/views/customerService/privacyPolicy/index.vue')
  },
  //  运维中心 -- 客户服务 -- 咨询热线   /consulting-hotline
  {
    path: '/consulting-hotline',
    name: 'consultingHotline',
    component: () => import('../view/maintenanceManagement/views/customerService/consultingHotline/index.vue')
  },
  //  运维中心 -- 客户服务 -- 意见反馈   /advice-feedback
  {
    path: '/advice-feedback',
    name: 'adviceFeedback',
    component: () => import('../view/maintenanceManagement/views/customerService/adviceFeedback/index.vue')
  },
  // 运维中心 -- 版本管理 -- 设备版本    /device-version
  {
    path: '/device-version',
    name: 'deviceVersion',
    component: () => import('../view/maintenanceCenter/versionmanage/deviceversion.vue')
  },
  // 运维中心 -- 版本管理 -- 版本升级    /version-grade
  {
    path: '/version-grade',
    name: 'versionGrade',
    component: () => import('../view/maintenanceCenter/versionmanage/versionGrade.vue')
  },
  // 运维中心 -- 版本管理 -- 版本升级    /update-version-grade
  {
    path: '/update-version-grade',
    name: 'updateVersionGrade',
    component: () => import('../view/maintenanceCenter/versionmanage/updateVersionGrade.vue')
  },
  // 运维中心 -- 边端服务 -- 消费监测   /tcp-consumption-monitoring
  {
    path: '/tcp-consumption-monitoring',
    name: 'tcpConsumptionMonitoring',
    component: () => import('../view/maintenanceManagement/views/edgeServices/consumptionMonitoring/index.vue')
  },
  // 运维中心 -- 边端服务 -- 异常监测   /tcp-abnormal-list
  {
    path: '/tcp-abnormal-list',
    name: 'tcpAbnormalList',
    component: () => import('../view/maintenanceManagement/views/edgeServices/abnormalList/index.vue')
  },
  //  运维中心 --  ai助理 -- 问答库   /answer-library
  {
    path: '/answer-library',
    name: 'answerLibrary',
    component: () => import('../view/maintenanceManagement/views/aiAssistant/answerLibrary/index.vue')
  },
  //  运维中心 --  ai助理 -- 体验用户   /experience-users
  {
    path: '/experience-users',
    name: 'experienceUsers',
    component: () => import('../view/maintenanceManagement/views/aiAssistant/experienceUsers/index.vue')
  },
  //  运维中心 --  ai助理 -- 用户审核   /user-review
  {
    path: '/user-review',
    name: 'userReview',
    component: () => import('../view/maintenanceManagement/views/aiAssistant/userReview/index.vue')
  },
  //  运维中心 --  ai助理 -- 问答记录  /answer-record
  {
    path: '/answer-record',
    name: 'answerRecord',
    component: () => import('../view/maintenanceManagement/views/aiAssistant/answerRecord/index.vue')
  },
  //  运维中心 --  ai助理 -- 聊天记录  /chatting-record
  {
    path: '/chatting-record',
    name: 'chattingRecord',
    component: () => import('../view/maintenanceManagement/views/aiAssistant/chattingRecord/index.vue')
  },
  // 运维中心 -- 版本管理
  {
    path: '/version-management',
    name: 'versionManagement;',
    component: () => import('../view/maintenanceManagement/views/versionManagement/addVersionManagement/index.vue')
  }
]
export default maintenanceCenter
